import React from 'react';
import { Link } from 'gatsby';

import * as styles from '../css/pages/TandC.module.scss';

import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';

const TandC = () => (
    <>
        <SEO title="Terms and Conditions" path="/terms-and-conditions/" />
        <Layout mainClass={styles.TandC}>
            <section className={styles.content}>
                <h1>Terms &amp; Conditions</h1>
                <hr />
                <p>
                    <strong>IMPORTANT – READ CAREFULLY:</strong>
                </p>
                <p>
                    This Member Licence Agreement (the “Agreement”) is a legal
                    agreement between you (either an individual or a single
                    entity) and Roundups Fundraising Services Ltd. (“Roundups'')
                    for evaluation and use of Roundups (including all ancillary
                    Applications provided) and any associated documentation
                    provided with Roundups (collectively, the “Roundups”, the
                    “Roundups App'' or the “App”).
                </p>
                <p>
                    By downloading, installing, or using the the Roundups
                    Service:
                </p>
                <ul>
                    <li>
                        you represent that you understand the terms of this
                        Agreement and you have the capacity and authority to
                        bind yourself to this Agreement, and
                    </li>
                    <li>
                        you accept the terms of this Agreement and you consent
                        to be bound by this Agreement (hereinafter referred to
                        as “you”).
                    </li>
                </ul>
                <p>
                    If you do not agree to be bound by these terms and
                    conditions, do not download, install, or use Roundups.
                </p>
                <p>
                    <strong>GENERAL LICENCE GRANT</strong>
                </p>
                <p>
                    Pursuant to an email or other written communication from
                    Roundups with the licence details for the Roundups Service
                    (“Confirmation”), and subject to payment of all applicable
                    licence fees, commissioner or other charges, Roundups grants
                    you the limited, non-exclusive, non-sublicensable,
                    non-transferable licence to install or use Roundups in
                    accordance with this Agreement and subject to any volume,
                    field of use, configuration or other restrictions set forth
                    in the Confirmation. If other documentation contains terms
                    in addition to, or different than, the terms of this
                    Agreement, those terms in that documentation shall be
                    disregarded and the terms of this Agreement shall take
                    precedence.
                </p>
                <p>
                    Grant of any licence pursuant to this Agreement is subject
                    to the{' '}
                    <Link to="/privacy-policy">Roundups Privacy Policy</Link>,
                    which shall Apply to any processing of personal data by
                    Roundups. Processing of data is also explained and provided
                    for in this Agreement. By entering into this Licence you
                    agree to the processing of your personal data in accordance
                    with the terms of this Agreement and to the terms of the
                    Roundups’s Privacy Policy.
                </p>
                <p>
                    <strong>LICENCE OPTIONS</strong>
                </p>
                <p>
                    Your licence to install and use Roundups shall be in
                    accordance with one or more of the following licence
                    options, as set forth on the Confirmation or on your Member
                    Account. Roundups may contain a software licence management
                    tool (a “Licence Manager”) that regulates your use of the
                    service. If so, all of the licenced activity described below
                    must be subject to the control of the licence Manager, and
                    you may not install or use Roundups in a manner that
                    circumvents or interferes with the operation of the licence
                    Manager or any other technological measure that controls
                    access to Roundups.
                </p>
                <p>
                    <strong>General Licence:</strong>
                </p>
                <ol>
                    <li>
                        Members may install Roundups on their devices or use the
                        web version in the number specified in Roundups Account
                        Confirmation. Roundups is licenced under each
                        Confirmation on a subscription basis per individual
                        Member (General Licence). The term of each General
                        Licence shall be as set out in Roundups Account
                        Confirmation and shall commence on the start date
                        specified on Roundups Account Confirmation
                        (“Subscription Term”). The General Licence shall
                        automatically terminate upon expiry of Roundups
                        Subscription Term unless the parties have agreed upon an
                        automatic renewal of the term pursuant to the terms of
                        the Confirmation.
                    </li>
                    <li>
                        Members may copy and install on Member’s devices, for
                        use only by the Member, as many copies of Roundups as is
                        designated on Roundups Confirmation.
                    </li>
                    <li>
                        All commission payments shall be payable per authorised
                        Member as set forth in Roundups Account Confirmation.
                    </li>
                    <li>
                        Any payments due for rounding up transactions,
                        commission and donations will be handled by Stripe.
                        Stripe is a third-party payment platform, registered
                        with the Financial Conduct Authority in the United
                        Kingdom and operates globally. Roundups cannot be held
                        liable for any breaches in Stripe’s security systems as
                        well as any losses suffered by you as a result of
                        Stripe’s system failure or similar events outside of
                        Roundups’ control. You agree to make yourself familiar
                        with Stripe’s payment terms available{' '}
                        <a
                            target="_blank"
                            href="https://stripe.com/gb/ssa"
                            rel="noreferrer">
                            here
                        </a>
                        . More information on payment processing can be found
                        below.
                    </li>
                </ol>
                <p>
                    <strong>RESTRICTIONS</strong>
                </p>
                <p>
                    It is your responsibility to ensure compliance with the
                    terms of this Agreement. Except as may otherwise expressly
                    be provided herein and as otherwise expressly permitted by
                    law, you shall not, directly or indirectly:
                </p>
                <ol>
                    <li>
                        Use Roundups in a manner that infringes any third
                        party’s copyrights or any other rights;
                    </li>
                    <li>
                        Make any translation, adaptation, arrangement,
                        modification, derivative work or other alteration of
                        Roundups;
                    </li>
                    <li>
                        Distribute, sell, give away, hire or lease Roundups, or
                        another product wholly or partially derived from
                        Roundups, or offer to do any of the foregoing;
                    </li>
                    <li>
                        Assign, sell, lease, rent, or otherwise make all or any
                        part of Roundups available for installation or use by
                        any third party, as a service bureau, Application
                        service provider or otherwise, without the prior written
                        consent of Roundups;
                    </li>
                    <li>
                        Decipher, decompile, disassemble or reverse engineer
                        Roundups, in whole or in part. To the extent you are
                        expressly permitted by law to reverse engineer Roundups,
                        you agree to use such findings only as expressly
                        permitted by law, and to otherwise hold such findings in
                        strict confidence. For information regarding
                        interoperability, contact Roundups.
                    </li>
                </ol>
                <p>
                    <strong>TRANSFER AND ASSIGNMENT</strong>
                </p>
                <p>
                    This Agreement is personal and may not be assigned or
                    assumed, including by operation of law, without Roundups’
                    prior written consent, and any attempt to do so without such
                    consent is void. You may, however, transfer Roundups and the
                    data generated by the use of Roundups to a successor in
                    interest to all or substantially all of your assets or stock
                    on a permanent basis, provided you retain no copies and the
                    recipient agrees to the terms of this Agreement.
                </p>
                <p>
                    <strong>TERM AND TERMINATION OF LICENCE</strong>
                </p>
                <ol>
                    <li>
                        The term of this Agreement, and the licence granted
                        hereunder, shall commence upon the date provided for in
                        Roundups Account Confirmation and shall continue for the
                        term stated on Roundups Account Confirmation as issued
                        by Roundups or, if no specific term is stated, shall
                        continue indefinitely unless terminated in accordance
                        with this Agreement.
                    </li>
                    <li>
                        This Agreement and the licence granted hereunder shall
                        terminate immediately and automatically if you have not
                        paid any commission or donation fees or other charges
                        due to Roundups within 30 days after such amount is due.
                    </li>
                    <li>
                        Without prejudice to any other rights, Roundups may
                        immediately terminate this Agreement and the licence
                        granted hereunder upon written notice to you if you fail
                        to comply with the terms and conditions of this
                        Agreement.
                    </li>
                    <li>
                        Upon termination of the licence for any reason, you must
                        immediately destroy and stop using all copies of
                        Roundups (including purging all storage media on which
                        such Software is installed or otherwise stored) and all
                        of its component parts.
                    </li>
                </ol>
                <p>
                    <strong>MEMBER COMMUNICATIONS</strong>
                </p>
                <p>
                    Any communications from the Roundups Members via the
                    Roundups app are sent to a third party GDPR-compliant
                    provider which encrypts data at rest and abides by all
                    Applicable best privacy practices. Roundups can access this
                    platform via an industry-standard HTTPS (TLS 1.2) secured
                    connection where Roundups can view the details of any
                    communication. There is a secure integration to Roundups’
                    encrypted email platform, which allows Roundups to respond
                    quickly and securely to any Member inquiries.
                </p>
                <p>
                    Any other communication sent to Roundups by Members either
                    directly via Roundups’s website forms or directly via email,
                    are stored and managed on servers which can then be accessed
                    to read those emails and communications.
                </p>
                <p>
                    <strong>SUPPORT</strong>
                </p>
                <p>
                    The provision of support services by Roundups, if any, shall
                    be subject to the terms of this Agreement. Roundups shall
                    provide the support and maintenance services to all Members.
                    Members can submit support requests either via their profile
                    on the Roundups Service or via email to{' '}
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                    .
                </p>
                <p>
                    <strong>TITLE</strong>
                </p>
                <p>
                    All copyrights, trademarks and all other intellectual
                    property rights in and to Roundups are and shall remain the
                    sole and exclusive property of Roundups. Nothing in this
                    Agreement shall confer any rights in any trade name,
                    business name or trademark of Roundups to you. All
                    modifications and improvements made to the Roundups Service
                    and derivative works of Roundups created by Roundups based
                    in whole or in part upon the suggestions or feedback
                    provided by you shall remain the sole and exclusive property
                    of Roundups. You agree not to remove any copyright,
                    trademark, proprietary rights, disclaimer or warning notice
                    included on or embedded in any part of Roundups. You agree
                    to keep confidential and use your best efforts to prevent
                    and protect the contents and output of Roundups from
                    unauthorised disclosure.
                </p>
                <p>
                    <strong>CONTENT</strong>
                </p>
                <p>
                    Title, ownership rights, and intellectual property rights in
                    and to the content accessed through Roundups is the property
                    of Roundups content owner and may be protected by Applicable
                    copyright or other law. This Agreement gives you no rights
                    to any such content.
                </p>
                <p>
                    <strong>FUNCTIONS AND USE OF DATA</strong>
                </p>
                <p>
                    Roundups designs, builds and maintains a platform allowing
                    members to donate to Good Causes in convenient and engaging
                    ways.
                </p>
                <p>
                    Donations are including but not limited to low value amounts
                    of 99 pence or less. The donations are based on rounding up
                    your digital payments to a specific “Roundup Figure” that
                    Members must specify when setting up their account. For
                    example, you can choose to round up your transactions to
                    £0.10, £0.50 or £1.00. Members have constant access to their
                    historical donations log via the Roundups app.
                </p>
                <p>
                    The Good Causes that Members can donate to can be Registered
                    Charities and schools as well as non-regulated social
                    enterprises, limited companies or other corporate structures
                    supporting causes beneficial to the society at large or
                    specific groups in need of help.
                </p>
                <p>
                    The Good Causes available on the platform are pre-selected.
                    Members cannot use the platform to donate to other Good
                    Causes which have not yet been added. Members can submit
                    requests for invitations of Good Causes, however those will
                    be invited and added upon Roundups’ sole discretion.
                </p>
                <p>
                    Roundups carry out a limited degree of due diligence on the
                    Good Causes available on the platform and Roundups cannot be
                    held responsible for how Good Causes use the donations
                    distributed by Roundups. More information on the vetting
                    process for Good Causes can be found on{' '}
                    <Link to="/faqs/">our FAQs</Link>.
                </p>
                <p>
                    Roundups is not a payment service provider. The mission of
                    the service is to offer innovative and engaging ways for
                    individuals to donate to Good Causes, encourage altruism via
                    new donation mechanism&nbsp;s; make the experience as
                    rewarding as possible and build a community of like-minded
                    philanthropists.
                </p>
                <p>
                    Roundups do not store any financial data. All data necessary
                    for the operation of the service is processed and stored as
                    explained in this Agreement and in compliance with the
                    applicable laws and regulations.
                </p>
                <p>
                    <strong>
                        PAYMENTS, DONATIONS, FEES AND PAYMENT PROCESSING
                    </strong>
                </p>
                <p>
                    Roundups Fundraising Services Ltd receives 5% of all
                    donations as a commission to ensure the functioning of the
                    app and all applicable software as well as any
                    administration required to provide such services to Members
                    and Good Causes. This administration cost covers third party
                    costs such as payment processing fees and data aggregation.
                    Further, it covers business running costs such as technical
                    infrastructure and marketing. This commission fee is
                    deducted from donations before they are disbursed to
                    Members’ chosen Good Causes.
                </p>
                <p>
                    Some of the processes required to use the Roundups app are
                    regulated by the Financial Conduct Authority. The processes
                    that are FCA regulated are managed by authorised
                    organisations Salt Edge Limited and Stripe Payments UK,
                    Ltd., as explained below. Roundups itself is not regulated
                    as per the requirements of the Financial Conduct Authority.
                </p>
                <p>
                    In order to offer the Roundups donation mechanism, the
                    platform requires read-only access to your online bank
                    statement, with your permission. To grant such permission,
                    Members must enter into an agreement with our trusted
                    partner,{' '}
                    <a
                        target="_blank"
                        href="https://www.saltedge.com/"
                        rel="noreferrer">
                        Salt Edge Limited
                    </a>
                    , a company registered in England and Wales (Company Number:
                    11178811) and authorised by the FCA (Registration Number
                    822499). Salt Edge Limited is a subsidiary of Salt Edge Inc
                    and its registered office is 71-75 Shelton Street, Covent
                    Garden, London, England, WC2H 9JQ
                </p>
                <p>
                    Salt Edge Ltd provides a secure service which allows the app
                    to connect to members’ financial institutions through{' '}
                    <a
                        target="_blank"
                        href="https://www.openbanking.org.uk/"
                        rel="noreferrer">
                        Open Banking
                    </a>{' '}
                    APIs. You can read more about Open Banking APIs{' '}
                    <Link to="/faqs/?q=What is Open Banking?">here</Link>. You
                    can read more about the data we receive from Salt Edge{' '}
                    <Link to="/faqs/">here</Link>. By using Roundups, you
                    indicate that you have read and agree to the{' '}
                    <a
                        target="_blank"
                        href="https://www.saltedge.com/pages/end_user_license_terms"
                        rel="noreferrer">
                        Salt Edge Terms of Service
                    </a>
                    ,{' '}
                    <a
                        target="_blank"
                        href="https://www.saltedge.com/pages/dashboard_terms_of_service"
                        rel="noreferrer">
                        Salt Edge Dashboard Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        target="_blank"
                        href="https://www.saltedge.com/pages/dashboard_privacy_policy"
                        rel="noreferrer">
                        Salt Edge Dashboard Privacy Policy
                    </a>
                    .
                </p>
                <p>
                    Via Salt Edge, Roundups app securely “reads” transaction
                    data from your online bank statement several times per day.
                </p>
                <p>
                    The Roundups app then applies a logic to your transactions
                    which calculates what your donations should be based on your
                    chosen Roundup Figure Configuration. You can find more
                    information and examples of the logic and calculations{' '}
                    <Link to="/faqs/">here</Link>.
                </p>
                <p>
                    Payments for Round Up Donations, which are calculated as
                    outlined above are initiated when the Round Up Donations
                    accumulate to a total of £5.00. Delays to this initiation
                    are possible, however Roundups will put all reasonable
                    effort to prevent any such delays.
                </p>
                <p>
                    In order to process donations, Members must provide a
                    payment card when setting up their Roundups account. To
                    process members’ payment card information, Roundups use the
                    secure payment processing platform,{' '}
                    <a
                        target="_blank"
                        href="https://stripe.com/"
                        rel="noreferrer">
                        Stripe Payments UK, Ltd
                    </a>
                    .
                </p>
                <p>
                    Stripe Payments UK, Ltd. (Company No. 08480771) is
                    authorised as an electronic money institution by the U.K.
                    Financial Conduct Authority (reference number: 900461) to
                    issue electronic money, enable cash placement and cash
                    withdrawal services on payment accounts, execute payment
                    transactions, make money remittances, and acquire payment
                    transactions (the “Authorised Payment Services”). Their
                    Registered Office is: 7th Floor, The Bower Warehouse, 211
                    Old Street, London EC1V 9NR, United Kingdom.
                </p>
                <p>
                    Members’ payment card information is not received,
                    processed, transmitted or stored in any way by Roundups
                    Fundraising Services Ltd. Members’ payment card information
                    is received and processed by Stripe Payments UK, Ltd.
                    Members’ payment card information will be stored by Stripe
                    Payments UK, Ltd and made available for recurring payments.
                    Roundups uses the Stripe Connect service to safely store and
                    remit donations to Good Causes once per month.
                </p>
                <p>
                    <strong>INSURANCE</strong>
                </p>
                <p>
                    Roundups will maintain appropriate insurance policies to
                    ensure that all your rights as a Member are fully protected.
                    The policy shall be made available by Roundups upon request
                    and shall cover all matters relating to Professional
                    Indemnity, Cyber and Privacy Liability and all other costs
                    associated with running and maintaining Roundups and its
                    applicable datasets.
                </p>
                <p>
                    <strong>LIMITED WARRANTY</strong>
                </p>
                <p>
                    Roundups warrants to you, for thirty (30) days from the date
                    of the Account Confirmation, Roundups shall be in operable
                    condition as described in the documentation provided as part
                    of Roundups. Roundups do not warrant that the service will
                    function without errors or interruptions. In the event that
                    Roundups fails to conform to this warranty, Roundups’ sole
                    liability and your exclusive remedy shall be, at Roundups’s
                    option, either
                </p>
                <ul>
                    <li>return of the price paid by you, or</li>
                    <li>repair or replacement of the Roundups Service.</li>
                </ul>
                <p>
                    This limited warranty does not apply to Software identified
                    as “beta,” “pre-release,” “test,” “as-is,” or the like, or
                    to any support or other services performed by Roundups, all
                    of which are supplied on an “as-is” basis without any
                    warranty of any kind. This limited warranty is void if
                    failure of the Roundups Service resulted from your
                    negligence, abuse, accident, or improper or unauthorised use
                    of Roundups. The foregoing limited warranty is in lieu of
                    and Roundups disclaims, to the maximum extent permitted by
                    Applicable law, all other warranties, express or implied
                    (whether by statute, common law, custom, usage or
                    otherwise), including but not limited to warranties of
                    merchantability, fitness for a particular purpose,
                    conditions of quality, and non infringement of third party
                    rights. The foregoing limited warranty shall further not be
                    enlarged or otherwise affected by Roundups’s rendering of
                    any support services or technical or other advice or service
                    in connection with Roundups or your use thereof. Roundups
                    shall not be held responsible for the performance of or
                    output obtained from Roundups nor for any liability to any
                    party arising out of use of Roundups. This warranty gives
                    you specific legal rights, and you may also have other
                    rights which vary from country to country.
                </p>
                <p>
                    <strong>LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                    In no event shall Roundups be liable to you for loss of
                    prospective profits or special, indirect or consequential
                    damages even if Roundups is advised of the possibility of
                    such damage. In addition, in no event shall the liability of
                    Roundups to you under this agreement exceed an amount equal
                    to that paid by you to Roundups in connection with your use
                    of Roundups within the previous twelve-month period.
                </p>
                <p>
                    <strong>EXPORT</strong>
                </p>
                <p>
                    You agree that the Roundups will not be shipped,
                    transferred, exported, or re-exported into any country or
                    used in any manner prohibited by any Applicable export laws,
                    restriction or regulations (collectively, the “Export
                    Laws”). If Roundups, or any component thereof, is identified
                    as an export controlled item under the Export Laws, you
                    represent and warrant that you are not a citizen, or
                    otherwise located within, an embargoed nation and that you
                    are not otherwise prohibited under the Export Laws from
                    receiving Roundups. All rights to use Roundups under this
                    Agreement are granted on the condition that such rights are
                    forfeited if your representations and warranties in this
                    section are not true.
                </p>
                <p>
                    <strong>JURISDICTION</strong>
                </p>
                <p>
                    This Agreement is governed by the laws of England and Wales.
                    You hereby consent to the jurisdiction of the courts of
                    England.
                </p>
                <p>
                    <strong>LEGAL INFORMATION</strong>
                </p>
                <p>
                    Roundups are fully developed, owned and run by Roundups
                    Fundraising Services Ltd., a company registered in England,
                    company number 11639162, registered address: 56a Paul
                    Street, London, EC2A 4LN.
                </p>
                <p>
                    Should you have any questions regarding Roundups or the
                    Roundups Service and the App you can email the team at{' '}
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                </p>
                <p>
                    <strong>DATA PROTECTION AND STORAGE</strong>
                </p>
                <p>
                    Any data collected through the Roundups Service and sent
                    over to Roundups’ backend infrastructure will be stored in
                    GDPR-compliant servers which encrypt data at rest. Roundups
                    also encrypt data in transit using HTTPS (TLS 1.2).
                </p>
                <p>
                    Any data stored on backend servers have carefully controlled
                    access-control systems which monitor and display whoever
                    accesses the servers at any given time.
                </p>
                <p>
                    All data is stored by a third-party provider, the same used
                    for managing the Roundups integrated communications and
                    feedback platform.
                </p>
                <p>
                    Roundups cannot be held liable for any breaches of security
                    on third-parties servers outside of Roundups’s control.
                    Whilst Roundups undertakes to notify all Members of any
                    breaches in third-party security systems immediately, you
                    accept that this may not always be possible and reasonable
                    time shall be given to all parties to establish, investigate
                    and remedy any such breaches.
                </p>
                <p>
                    <strong>ICO</strong>
                </p>
                <p>
                    If you are located in the United Kingdom and you think that
                    your personal data rights have been breached as a result of
                    using Roundups, please refer to the Information
                    Commissioner’s Office for guidance. Please contact Roundups
                    by emailing{' '}
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>{' '}
                    first. If you feel that your issue or request has not been
                    resolved, please contact the Information Commissioner’s
                    Office at{' '}
                    <a
                        target="_blank"
                        href="https://www.ico.org.uk/"
                        rel="noreferrer">
                        www.ico.org.uk
                    </a>{' '}
                    or the following address:
                </p>
                <p>Information Commissioner’s Office</p>
                <p>
                    Wycliffe House
                    <br />
                    Water Lane
                    <br />
                    Wilmslow
                    <br />
                    Cheshire
                    <br />
                    SK9 5AF
                </p>
                <p>
                    <strong>CONSUMER RIGHTS</strong>
                </p>
                <p>
                    You are protected under consumer law and are entitled to a
                    statutory refund under certain circumstances. If you believe
                    your consumer rights have been affected by Roundups or the
                    Use of Roundups please contact Roundups on{' '}
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                    . Please include a description of your issue along with the
                    country of residence so Roundups’ Support team can direct
                    you to the relevant authorities in your country. In case any
                    refund is due, please allow 30 days before it is processed.
                </p>
                <p>PLEASE NOTE: Any donations accrued will not be refunded.</p>
                <p>
                    <strong>AGE RESTRICTIONS </strong>
                </p>
                <p>
                    All Members must be at least 18 years old to use the
                    Roundups Service. Roundups reserves the right to check the
                    age of anybody installing and creating an account on
                    Roundups regardless of their location or licence rights.
                </p>
                <p>
                    <strong>GENERAL</strong>
                </p>
                <p>
                    This Agreement shall ensure to the benefit of Roundups and
                    its successors or assigns.
                </p>
                <p>
                    Waiver of breach of any provision of this Agreement shall
                    not be deemed a waiver of that provision or any other
                    provision.
                </p>
                <p>
                    If any provision of this Agreement is held to be
                    unenforceable, it will be enforced to the maximum extent
                    permissible, and the remaining provisions will remain in
                    full force.
                </p>
                <p>
                    You acknowledge that you have read this Licence Agreement,
                    understand it and agree to be bound by its terms and
                    conditions.
                </p>
                <p>
                    You further agree that this agreement, together with one or
                    more account confirmations for Roundups, is the complete and
                    exclusive statement of the agreement between you and
                    Roundups which supersedes any proposal, or prior agreement,
                    oral or written, and any other communications between you
                    and Roundups relating to the subject matter hereof.
                </p>
                <p>
                    Roundups reserves the right to amend this Agreement without
                    giving prior notice. Whilst all reasonable endeavour will be
                    made to notify all Members of any changes to this Agreement,
                    it is your responsibility to familiarise yourself with its
                    provisions on a regular basis.
                </p>
                <p>
                    If you have any questions about this Agreement, or if you
                    wish to change the address to which notices may be sent to
                    you for purposes of this Agreement, you may write to
                    Roundups at{' '}
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                    .
                </p>
            </section>
        </Layout>
    </>
);

export default TandC;
